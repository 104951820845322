<aside class="menu-container">
  <div class="link-container">
    <img
      alt="logo"
      class="logo"
      height="60"
      ngSrc="../../../assets/navbar/logo60x60.png"
      priority="priority"
      width="60" />
    <div [routerLink]="'/'" class="nav-menu">
      <img
        alt="tableau de bord"
        height="50"
        ngSrc="../../../assets/navbar/dash.png"
        width="50" />
    </div>
    <div
      (mouseenter)="isOpen[0] = true"
      (mouseleave)="isOpen[0] = false"
      class="nav-menu"
      data-cy="mandats-menu">
      <img
        alt="mandats"
        height="24"
        ngSrc="../../../assets/navbar/dossier.png"
        width="24" />
      <div [ngClass]="{ show: isOpen[0] }" class="navlink-items">
        <p [routerLink]="'/mandats'" data-cy="mandats-actifs">Mandats actifs</p>
        <p [routerLink]="'/mandats/prospections'" data-cy="fichier-prospection">
          Fichier prospection
        </p>
        <p [routerLink]="'/mandats/lessors'" data-cy="bailleurs">Bailleurs</p>
        <p
          [queryParams]="{ prospection: false }"
          [routerLink]="'/mandats/checking'"
          data-cy="creer-pre-mandat">
          Créer un pré-mandat
        </p>
        <p
          [queryParams]="{ prospection: true }"
          [routerLink]="'/mandats/checking'"
          data-cy="creer-fiche-prospection">
          Créer une fiche de prospection
        </p>
      </div>
    </div>
    <div
      (mouseenter)="isOpen[1] = true"
      (mouseleave)="isOpen[1] = false"
      class="nav-menu">
      <img
        alt="acquéreurs"
        height="24"
        ngSrc="../../../assets/navbar/users.png"
        width="24" />
      <div [ngClass]="{ show: isOpen[1] }" class="navlink-items">
        <p [routerLink]="['/buyers', 'false']">Mes acquéreurs</p>
        <p [routerLink]="['/buyers', 'true']">Nos acquéreurs</p>
        <p [routerLink]="['/buyers/new/checking']">Créer un acquéreur</p>
        <p [routerLink]="'/business/new/create'">Créer une offre d'achat</p>
      </div>
    </div>
    <div
      (mouseenter)="isOpen[2] = true"
      (mouseleave)="isOpen[2] = false"
      class="nav-menu"
      data-cy="business-menu">
      <img
        alt="affaires"
        height="24"
        ngSrc="../../../assets/navbar/business.png"
        width="24" />
      <div [ngClass]="{ show: isOpen[2] }" class="navlink-items">
        <p [routerLink]="'/business'" data-cy="businesses" >Affaires en cours</p>
        <p [routerLink]="'/invoices'">Factures</p>
      </div>
    </div>
  </div>
  <div
    (mouseenter)="isOpen[4] = true"
    (mouseleave)="isOpen[4] = false"
    class="link-container">
    <div class="nav-menu">
      <img
        alt="archives"
        height="24"
        ngSrc="../../../assets/navbar/boite.png"
        width="24" />

      <div [ngClass]="{ show: isOpen[4] }" class="navlink-items">
        <p [routerLink]="'/mandats/archived'">Mandats archivés</p>
        <p [routerLink]="'/buyers/archived'">Acquéreurs archivés</p>
        <p [routerLink]="'/business/handled'">Affaires traitées</p>
      </div>
    </div>
  </div>
  <div
    (mouseenter)="isOpen[5] = true"
    (mouseleave)="isOpen[5] = false"
    class="link-container">
    <div class="nav-menu">
      <img
        alt="information"
        height="24"
        ngSrc="../../../assets/navbar/home.png"
        width="24" />
      <div [ngClass]="{ show: isOpen[5] }" class="navlink-items">
        <p [routerLink]="'agency/informations'">Informations agence</p>
        <p [routerLink]="'agency/fees'">Barème honoraires</p>
        <p [routerLink]="'/admin/invoice-network'">Factures réseaux</p>
        <p [routerLink]="'passerelles/leboncoin'">Passerelle LeBonCoin</p>
      </div>
    </div>
  </div>
  <div
    (mouseenter)="isOpen[6] = true"
    (mouseleave)="isOpen[6] = false"
    class="link-container">
    <div class="nav-menu">
      <img
        alt="utilisateurs"
        height="24"
        ngSrc="../../../assets/navbar/cadenas.png"
        width="24" />
      <div [ngClass]="{ show: isOpen[6] }" class="navlink-items">
        <p [routerLink]="'super-admin/agencies/create'">Créer une agence</p>
        <p [routerLink]="'super-admin/agencies/list'">Liste des agences</p>
        <p [routerLink]="'super-admin/passerelles'">Gestion passerelles</p>
        <p [routerLink]="'super-admin/invoice-network'">Factures reseaux</p>
        <p [routerLink]="'super-admin/consultants'">Gestion des utilisateurs</p>
        <p>Logs API</p>
      </div>
    </div>
  </div>
  <div
    (mouseenter)="isOpen[7] = true"
    (mouseleave)="isOpen[7] = false"
    class="link-container">
    <div class="nav-menu">
      <app-consultant-avatar
        *ngIf="consultantId"
        [consultantId]="consultantId" />
      <div [ngClass]="{ show: isOpen[7] }" class="navlink-items">
        <p [routerLink]="'account'">Mon compte</p>
        <p
          (click)="logout()"
          (keydown.enter)="logout()"
          class="logout"
          tabindex="0">
          Se déconnecter
        </p>
      </div>
    </div>
  </div>
</aside>
